import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { OrderPage } from './order.page';
import { SharedModuleModule } from '../../shared-module/shared-module.module';

const routes: Routes = [
	{
		path: '',
		component: OrderPage,
	},
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModuleModule],
	declarations: [OrderPage],
	exports: [OrderPage],
})
export class OrderPageModule {}
