import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import OptionGroup from '../../../models/OptionGroup';
import ArticleOption from '../../../models/ArticleOption';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import Article from '../../../models/Article';
import { PreorderType } from '../../../enums/PreorderType';
import Utils from '../../../utils';
import { OrderType } from '../../../enums/OrderType';

@Component({
	selector: 'app-article-option-group-flavor',
	templateUrl: './article-option-group-flavor.component.immergruen.html',
	styleUrls: ['./article-option-group-flavor.component.immergruen.scss'],
})
export class ArticleOptionGroupFlavorComponent implements OnInit {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() preorderType: PreorderType;

	options: ArticleOption[] = [];
	di = DisplayIdentifier;

	constructor() {}

	ngOnInit() {
		for (const article of this.optionGroup.articles) {
			const articleOption = new ArticleOption();
			articleOption.article = article;
			articleOption.group = this.optionGroup._id;
			articleOption.quantity = 1;
			this.options.push(articleOption);
		}
	}

	onAdd(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	onRemove(article: Article) {
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = -1;
		this.selection.emit(articleOption);
	}

	count(article: Article) {
		return this.selected
			.filter(selected => selected.group === this.optionGroup._id && selected.article._id === article._id)
			.map(value => value.quantity)
			.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
	}

	isHidden(article: Article): boolean {
		return this.preorderType && !Utils.getAvailability(article, OrderType.PREORDER, this.preorderType);
	}
}
