import { Component, Input, OnInit } from '@angular/core';
import Venue from '../../../models/Venue';
import Utils, { numberD } from '../../../utils';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-delivery-fees',
	templateUrl: './delivery-fees.component.html',
	styleUrls: ['delivery-fees.component.scss'],
})
export class DeliveryFeesComponent implements OnInit {
	@Input() venue: Venue;
	utils = Utils;

	constructor(private translate: TranslateService) {}

	ngOnInit() {}

	filterSpecifiedPostalCodes(venue: Venue): string[] {
		return venue.deliveryPostalCodes.filter(
			postalCode => venue.movAndFee.byPostalCodes.find(byPostal => byPostal.postalCode === postalCode) === undefined
		);
	}

	formatFee(fee: any): string {
		const safeFee = numberD(fee);
		if (safeFee === 0) {
			return this.translate.instant('delivery_fees_component.table.data.free_fee');
		}
		return '+' + Utils.numberToCurrency(safeFee, this.venue.currency);
	}

	formatMov(mov: any): string {
		return this.translate.instant('delivery_fees_component.table.data.mov', {
			mov: Utils.numberToCurrency(mov, this.venue.currency),
		});
	}

	formatRadius(radius: any): string {
		const safeRadius = numberD(radius);
		return this.translate.instant('delivery_fees_component.table.data.to_km', { km: safeRadius });
	}
}
