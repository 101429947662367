import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import {} from 'google-maps';
import Utils from '../../../utils';
import { AppComponent } from '../../app.component';
import RepositoryInterface from '../repository-directive';
import { environment } from '../../../environments/environment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { MapPage } from '../map/map.page';
import { MapsUtils } from '../../../utils/maps-utils';

@Component({
	selector: 'app-home',
	templateUrl: './home.page.immergruen.html',
	styleUrls: ['./home.page.immergruen.scss'],
})
export class HomePage extends RepositoryInterface implements OnInit {
	static url = 'home';
	environment = environment;

	@ViewChild('backgroundVideo', { static: true }) video: HTMLVideoElement;

	loading = false;

	utils = Utils;

	constructor(
		protected repository: RepositoryService,
		private router: Router,
		private cdr: ChangeDetectorRef,
		private analytics: AngularFireAnalytics
	) {
		super(repository);
	}

	static async navigate(router: Router, repository?: RepositoryService) {
		if (repository) {
			repository.resetVenue();
			repository.order.emit(null);
			repository.verifiedOrder.emit(null);
			repository.payment.emit(null);
		}
		await router.navigateByUrl(HomePage.url);
	}

	largeScreen(): boolean {
		return AppComponent.largeScreen;
	}

	ngOnInit() {
		super.ngOnInit();
		window.onfocus = () => {
			if (this.video && this.video.paused) {
				this.video
					.play()
					.then(() => {
						console.log('Video started successfully');
					})
					.catch(err => {
						console.error(err);
					});
			}
		};
	}

	ionViewDidEnter() {
		if (history.state.navigationId === 1) {
			this.repository.order.emit(null);
		}
		this.repository.verifiedOrder.emit(null);
	}

	async locateAndShowStores() {
		if (this.loading) {
			return;
		}
		this.loading = true;
		this.cdr.detectChanges();
		try {
			const address = MapsUtils.placeToAddress(await MapsUtils.getUserGeocode());
			this.repository.address.emit(address);
		} catch (e) {
			console.error(e);
			this.repository.address.emit(null);
		}
		await MapPage.navigate(this.router);
		this.loading = false;
		this.cdr.detectChanges();
	}
}
