import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { RepositoryService } from '../../services/repository/repository.service';
import firebase from 'firebase/app';
import 'firebase/auth';
import Customer from '../../../models/Customer';
import { AccountPage } from '../account/account.page';
import { AngularFireAuth } from '@angular/fire/auth';
import { HomePage } from '../home/home.page';
import { environment } from '../../../environments/environment';
import { MenuPage } from '../menu/menu.page';

@Component({
	selector: 'app-email-confirmation',
	templateUrl: './email-confirmation.page.immergruen.html',
	styleUrls: ['./email-confirmation.page.immergruen.scss'],
})
export class EmailConfirmationPage implements OnInit {
	static url = 'confirm-email';

	user: firebase.User;
	customer: Customer;
	loading = false;
	environment = environment;

	constructor(
		repository: RepositoryService,
		private router: Router,
		private auth: AngularFireAuth,
		private route: ActivatedRoute,
		private cdr: ChangeDetectorRef
	) {
		repository.user.subscribe(user => {
			this.user = user;
			this.reloadUser();
		});
		this.user = repository._user;
		this.reloadUser();
	}

	static async navigate(router: Router, replaceUrl: boolean = false) {
		await router.navigateByUrl(EmailConfirmationPage.url, {
			replaceUrl,
		});
	}

	static async navigateWithParams(router: Router, queryParams: Params) {
		await router.navigate([EmailConfirmationPage.url], {
			queryParams,
			replaceUrl: true,
		});
	}

	ngOnInit(): void {
		this.route.queryParams.subscribe(async params => {
			if (!params.oobCode || !params.mode) {
				return;
			}
			if (params.mode !== 'verifyEmail') {
				return;
			}
			await this.auth.applyActionCode(params.oobCode);
		});
	}

	reloadUser() {
		if (!this.user) {
			return;
		}
		if (this.user.isAnonymous) {
			HomePage.navigate(this.router);
		}
	}

	async refresh() {
		this.loading = true;
		try {
			await this.user.reload();
		} catch (e) {
			console.error(e);
		}
		this.loading = false;
		this.cdr.detectChanges();
	}

	async proceed() {
		this.route.queryParams.subscribe(async params => {
			if (params.promo || localStorage.getItem('promo')) {
				localStorage.removeItem('promo');
				await MenuPage.navigate(this.router);
			} else {
				await AccountPage.navigate(this.router);
			}
		});
	}
}
