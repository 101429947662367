import { Component } from '@angular/core';
import { HomePage } from '../../pages/home/home.page';
import { MenuPage } from '../../pages/menu/menu.page';
import { RepositoryService } from '../../services/repository/repository.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-toolbar-small',
	templateUrl: './toolbar-small.component.immergruen.html',
	styleUrls: ['./toolbar-small.component.immergruen.scss'],
})
export class ToolbarSmallComponent {
	environment = environment;

	constructor(private repository: RepositoryService, private router: Router) {}

	home() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
