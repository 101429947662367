import { Component, OnInit, ViewChild } from '@angular/core';
import { IonContent, IonSelect, ModalController, PickerController } from '@ionic/angular';
import { RepositoryService } from '../../services/repository/repository.service';
import Utils from '../../../utils';
import * as moment from 'moment';
import { Moment } from 'moment';
import { PreorderType } from '../../../enums/PreorderType';
import validator from 'validator';
import { TosPage } from '../../pages/tos/tos.page';
import { PrivacyPage } from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../pages/repository-directive';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { ValidationUtils } from '../../../utils/validation-utils';
import { OrderUtils } from '../../../utils/order-utils';
import { calculateGeoDistance, MapsUtils } from '../../../utils/maps-utils';
import { Router } from '@angular/router';
import { AnalyticsUtils } from '../../../utils/analytics-utils';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import Preorder from '../../../models/Preorder';
import TagUtils from '../../../utils/tag-utils';
import { TimeUtils } from '../../../utils/time-utils';
import Slot from '../../../models/Slot';

@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.immergruen.html',
	styleUrls: ['./checkout-modal.component.immergruen.scss'],
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	utils = Utils;
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	streetNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	hadPostalCode = false;
	isOpeningTimeSheet = false;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover',
	};
	isVenueOpen = false;

	@ViewChild(IonContent, { static: false }) content;
	@ViewChild(IonSelect, { static: false }) select;

	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AngularFireAnalytics
	) {
		super(repository);
		if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
		if (this.order && this.order.preorder && this.order.preorder.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
	}

	static async show(modalCtrl: ModalController): Promise<{ asap: boolean; orderAt: Moment; preorder: Preorder }> {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			showBackdrop: true,
			cssClass: 'checkout-modal-full',
			backdropDismiss: true,
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;
		await Utils.sleep(100);
		return result;
	}

	ngOnInit() {
		super.ngOnInit();
		AnalyticsUtils.beginCheckout(this.order, this.analytics);
		AnalyticsUtils.checkoutProgress(this.order, null, 1, this.analytics);
		AnalyticsUtils.customerBeginsCheckout(this.analytics);
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order && this.order.preorder && this.order.preorder.postalCode && this.order.preorder.postalCode.length > 0;
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.name = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.phoneCountry) {
					this.order.preorder.phoneCountry = this.repository._previousPreorder.phoneCountry;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName = this.repository._previousPreorder.companyName;
				}
				if (!this.order.preorder.floor) {
					this.order.preorder.floor = this.repository._previousPreorder.floor;
				}
				if (!this.order.preorder.subcard) {
					this.order.preorder.subcard = this.repository._previousPreorder.subcard;
				}
			}
		}
		this.hadPostalCode = this.hadPostalCode || postalCodeInOrder;
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode = this.hadPostalCode || (this.address && this.address.postalCode && this.address.postalCode.length > 0);
		this.reloadAddress();
	}

	onVenue() {
		this.isVenueOpen = Utils.isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.phoneCountry) {
				this.order.preorder.phoneCountry = this.customer.phoneCountry;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	async validate(): Promise<boolean> {
		this.nameError = null;
		this.emailError = null;
		this.phoneError = null;
		this.orderAtError = null;
		this.streetNumberError = null;
		this.postalCodeError = null;
		this.streetError = null;
		if (!this.venue || !this.order || !this.order.preorder) {
			this.snackbarCtrl.open(this.translate.instant('errors.data_error'), null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.order.preorder.name || this.order.preorder.name.length < 3) {
			this.nameError = this.translate.instant('errors.name');
			this.snackbarCtrl.open(this.nameError, null, {
				duration: 2000,
			});
			return false;
		}
		if (!this.order.preorder.email || !validator.isEmail(this.order.preorder.email)) {
			this.emailError = this.translate.instant('errors.email');
			this.snackbarCtrl.open(this.emailError, null, {
				duration: 2000,
			});
			return false;
		}
		if (!ValidationUtils.validatePhone(this.order.preorder.phoneCountry, this.order.preorder.phone)) {
			this.phoneError = this.translate.instant('errors.phone');
			this.snackbarCtrl.open(this.phoneError, null, {
				duration: 2000,
			});
			return false;
		}

		// Address was changed in checkout modal check if venue delivers to this new address
		// new address needs to be checked if its in the delivery range
		if (OrderUtils.isDelivery(this.order) && this.order.preorder.street !== this.address.street) {
			this.order.preorder.street = this.order.preorder.street.trim();
			this.order.preorder.number = this.order.preorder.number.trim();
			if (Utils.isEmpty(this.order.preorder.street)) {
				this.streetError = this.translate.instant('address.street');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000,
				});
				return false;
			}
			try {
				const pred =
					this.order.preorder.street +
					' ' +
					this.order.preorder.number +
					', ' +
					this.order.preorder.city +
					' ' +
					this.order.preorder.postalCode;
				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(this.translate, address, true);
				if (addressValidationResult) {
					this.streetError = addressValidationResult;
					return false;
				}
				const delivers =
					(this.venue.deliveryByRadius &&
						calculateGeoDistance(
							address.lat,
							address.lng,
							this.venue.location.coordinates[1],
							this.venue.location.coordinates[0]
						) <= this.venue.deliveryRadius) ||
					(this.venue.isPostalDelivery && this.venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					this.streetError = this.translate.instant('errors.street_delivery');
					this.snackbarCtrl.open(this.streetError, null, {
						duration: 2000,
					});
					return false;
				}
				this.repository.address.emit(address);
				this.order.preorder.street = this.address.street;
			} catch (e) {
				this.streetError = this.translate.instant('errors.street_not_found');
				this.snackbarCtrl.open(this.streetError, null, {
					duration: 2000,
				});
				return false;
			}
		}

		if (this.order.preorder.type === PreorderType.DELIVERY && (!this.order.preorder.number || this.order.preorder.number.length < 1)) {
			this.streetNumberError = this.translate.instant('errors.street_number');
			this.snackbarCtrl.open(this.streetNumberError, null, {
				duration: 2000,
			});
			return false;
		}
		if (
			this.order.preorder.type === PreorderType.DELIVERY &&
			(!this.order.preorder.postalCode || this.order.preorder.postalCode.length < 1)
		) {
			this.postalCodeError = this.translate.instant('errors.postal');
			this.snackbarCtrl.open(this.postalCodeError, null, {
				duration: 2000,
			});
			return false;
		}
		const orderAt = await this.sanitizedOrderAt();
		if (!this.order.orderAt) {
			this.orderAtError = this.translate.instant('errors.order_at');
			this.order.orderAt = null;
			this.snackbarCtrl.open(this.orderAtError, null, {
				duration: 2000,
			});
			return false;
		}
		if (orderAt.isBefore(moment())) {
			this.orderAtError = this.translate.instant('errors.order_at');
			this.order.orderAt = null;
			this.snackbarCtrl.open(this.orderAtError, null, {
				duration: 2000,
			});
			return false;
		}
		return true;
	}

	async openTimeSheet() {
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000,
			});
			return;
		}
		if (this.isOpeningTimeSheet) {
			return;
		}
		this.isOpeningTimeSheet = true;
		this.loading = true;
		try {
			const loadedSlots = await Utils.getSlots(
				this.venue,
				this.order.preorder.type,
				this.order.orderedArticles.map(oa => TagUtils.hasExtraSlotsTag(oa.article)).find(bol => bol) !== undefined
			);
			const slots: Slot[] = [];
			if (loadedSlots.length > 0 && this.isVenueOpen && TimeUtils.isSameDate(moment(loadedSlots[0].time), moment())) {
				slots.push(null);
			}
			slots.push(...loadedSlots);
			if ((slots.length === 1 && slots[0] === null) || slots.length < 1) {
				this.isOpeningTimeSheet = false;
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000,
				});
				return;
			}
			const options = {
				columns: [
					{
						name: 'slot',
						class: 'time-picker',
						options: slots.map(slot => {
							return {
								text: this.slotToText(slot ? moment(slot.time) : null),
								value: slot,
							};
						}),
					},
				],
				buttons: [
					{
						text: this.translate.instant('checkout_modal.cancel'),
						role: 'cancel',
					},
					{
						text: this.translate.instant('checkout_modal.confirm'),
						handler: value => {
							const realSelectedSlot = value.slot.value;
							let selectedSlot = value.slot.value;
							if (selectedSlot == null && slots.length > 0) {
								selectedSlot = slots[1];
							}
							const conflictingArticles = OrderUtils.slotConflictingArticlesInOrder(moment(selectedSlot.time), this.order);
							if (conflictingArticles.length > 0) {
								this.snackbarCtrl.open(
									this.translate.instant('checkout_modal.products_unavailable') +
										conflictingArticles
											.map(conflictingArticle => conflictingArticle.article.name.de)
											.reduce((previousValue, currentValue) => previousValue + ' ' + currentValue),
									null,
									{
										duration: 3000,
									}
								);
								return;
							}
							if (realSelectedSlot) {
								this.order.orderAt = moment(realSelectedSlot.time).local();
							} else {
								this.order.orderAt = null;
							}
						},
					},
				],
			};
			const picker = await this.pickerCtrl.create(options);
			await picker.present();
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000,
			});
			console.error(e);
		}
		this.isOpeningTimeSheet = false;
		this.loading = false;
	}

	async sanitizedOrderAt(): Promise<Moment> {
		let orderAt: any = null;
		if (!this.order.orderAt) {
			const slots = await Utils.getSlots(this.venue, this.order.preorder.type);
			if (slots.length <= 0) {
				return null;
			}
			orderAt = moment(slots[0].time).local();
		} else {
			orderAt = moment(this.order.orderAt);
		}
		return orderAt;
	}

	async close() {
		this.order.orderAt = await this.sanitizedOrderAt();
		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
		};
		if (!(await this.validate())) {
			await Utils.sleep(100);
			const firstErrorElement: HTMLElement = [...this.content.el.children].find(el => {
				return el.className.split(' ').findIndex(clazz => clazz === 'input-error') >= 0;
			});
			if (firstErrorElement !== undefined) {
				await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
			}
			return;
		}
		this.repository.previousPreorder.emit(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	reloadAddress() {
		if (OrderUtils.isDelivery(this.order) && this.address) {
			this.order.preorder.city = this.address.city;
			this.order.preorder.street = this.address.street;
			if (this.address.postalCode && this.address.postalCode.length > 0) {
				this.order.preorder.postalCode = this.address.postalCode;
			}
			this.order.preorder.country = this.address.country;
			this.order.preorder.number = this.address.number;
		}
	}

	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		if (!slot) {
			return this.translate.instant(this.isVenueOpen ? 'checkout_modal.first_slot' : 'checkout_modal.choose_slot');
		}
		const now = moment();
		const sameDay = slot.dayOfYear() === now.dayOfYear() && slot.year() === now.year();
		if (sameDay) {
			return slot.format('HH:mm');
		} else {
			return slot.format('DD.MM. HH:mm');
		}
	}

	getPlaceholder(key: string): string {
		return `${this.translate.instant(key)} ${this.translate.instant('checkout_modal.type')}`;
	}
}
