import { ApplicationRef, EventEmitter, Injectable } from '@angular/core';
import Venue from '../../../models/Venue';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import Order from '../../../models/Order';
import Payment from '../../../models/Payment';
import Customer from '../../../models/Customer';
import { AngularFireAuth } from '@angular/fire/auth';
import Address from '../../../models/Address';
import { Api, sanitizeId } from '../../../api/api';
import Utils, { numberComparator } from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';
import Preorder from '../../../models/Preorder';
import { environment } from '../../../environments/environment';
import OptionGroup from '../../../models/OptionGroup';
import Article from '../../../models/Article';
import { OrderUtils } from '../../../utils/order-utils';
import { TimeUtils } from '../../../utils/time-utils';
import { ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PaymentModalComponent, PaymentModalResult } from '../../components/payment-modal/payment-modal.component';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import moment from 'moment';
import { AngularFireAnalytics } from '@angular/fire/analytics';
import { AuthLoginResponse } from '../../../models/AuthLoginResponse';
import { AnalyticsUtils } from '../../../utils/analytics-utils';
import User = firebase.User;
import { MovAndFee } from '../../../models/MovAndFee';

const localStorageOrderKey = 'repository-service-order';
const localStorageVerifiedOrderKey = 'repository-service-verified-order';
const localStorageCustomerKey = 'repository-service-customer';
const localStoragePaymentKey = 'repository-service-payment';
const localStorageAddressKey = 'repository-service-address';
const localStoragePreviousPreorderKey = 'repository-service-previous-preorder';
const localStorageAuthLoginKey = 'repository-service-auth-login';

@Injectable({
	providedIn: 'root',
})
export class RepositoryService {
	venue = new EventEmitter<Venue>();
	address = new EventEmitter<Address>();
	user = new EventEmitter<User>();
	order = new EventEmitter<Order>();
	verifiedOrder = new EventEmitter<Order>();
	payment = new EventEmitter<Payment>();
	customer = new EventEmitter<Customer>();
	previousPreorder = new EventEmitter<Partial<Preorder>>();
	authLogin = new EventEmitter<AuthLoginResponse>();

	_venue: Venue;
	_address: Address;
	_order: Order;
	_verifiedOrder: Order;
	_payment: Payment;
	_customer: Customer;
	_user: User;
	_previousPreorder: Partial<Preorder>;
	_authLogin: AuthLoginResponse;

	private venuePromise: Promise<Venue> = null;
	private venuePromiseRunning: string = null;

	constructor(private auth: AngularFireAuth, private appRef: ApplicationRef, private analytics: AngularFireAnalytics) {
		this.user.subscribe(async user => {
			this._user = user;
			await this.reloadCustomer(user);
			appRef.tick();
		});

		this._venue = null;
		try {
			this._order = JSON.parse(localStorage.getItem(localStorageOrderKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageOrderKey, null);
		}
		try {
			this._payment = JSON.parse(localStorage.getItem(localStoragePaymentKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStoragePaymentKey, null);
		}
		try {
			this._customer = JSON.parse(localStorage.getItem(localStorageCustomerKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageCustomerKey, null);
		}
		try {
			this._address = JSON.parse(localStorage.getItem(localStorageAddressKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageAddressKey, null);
		}
		try {
			this._verifiedOrder = JSON.parse(localStorage.getItem(localStorageVerifiedOrderKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageVerifiedOrderKey, null);
		}
		try {
			this._previousPreorder = JSON.parse(localStorage.getItem(localStoragePreviousPreorderKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStoragePreviousPreorderKey, null);
		}
		try {
			this._authLogin = JSON.parse(localStorage.getItem(localStorageAuthLoginKey));
		} catch (e) {
			console.error(e);
			localStorage.setItem(localStorageAuthLoginKey, null);
		}

		this.order.subscribe(order => {
			if (order) {
				OrderUtils.injectRequiredArticles(this._venue, order, analytics);
				OrderUtils.injectDeliveryFees(this._venue, order);
				OrderUtils.sanitizePhoneCountry(order);
				order.orderedArticles = order.orderedArticles.map(articleGroup => {
					articleGroup.groups = articleGroup.groups.sort((a, b) => numberComparator(a.article.sortOrder, b.article.sortOrder));
					return articleGroup;
				});
			}
			this._order = order;
			localStorage.setItem(localStorageOrderKey, JSON.stringify(order));
			appRef.tick();
		});
		this.verifiedOrder.subscribe(verifiedOrder => {
			this._verifiedOrder = verifiedOrder;
			localStorage.setItem(localStorageVerifiedOrderKey, JSON.stringify(verifiedOrder));
			appRef.tick();
		});
		this.address.subscribe(address => {
			this._address = address;
			localStorage.setItem(localStorageAddressKey, JSON.stringify(address));
			appRef.tick();
		});
		this.payment.subscribe(payment => {
			this._payment = payment;
			localStorage.setItem(localStoragePaymentKey, JSON.stringify(payment));
			appRef.tick();
		});
		this.customer.subscribe(customer => {
			if (customer) {
				if (!customer.country) {
					customer.country = environment.countryList[0];
					customer.phoneCountry = Utils.phoneCountries[0];
				}
			}
			this._customer = customer;
			localStorage.setItem(localStorageCustomerKey, JSON.stringify(customer));
			appRef.tick();
		});
		this.venue.subscribe(venue => {
			this._venue = venue;
			this.order.emit(this._order);
			appRef.tick();
		});
		this.previousPreorder.subscribe(preorder => {
			this._previousPreorder = preorder;
			localStorage.setItem(localStoragePreviousPreorderKey, JSON.stringify(preorder));
		});
		this.authLogin.subscribe(authLogin => {
			this._authLogin = authLogin;
			localStorage.setItem(localStorageAuthLoginKey, JSON.stringify(authLogin));
		});

		this.address.emit(this._address);
		this.order.emit(this._order);
		this.verifiedOrder.emit(this._verifiedOrder);
		this.payment.emit(this._payment);
		this.customer.emit(this._customer);
		this.authLogin.emit(this._authLogin);
	}

	async reloadCustomer(user: firebase.User) {
		if (!user || user.isAnonymous) {
			this.sanitizeAndEmitCustomer(user, null);
			return;
		}
		let customer: Customer;
		try {
			const response = await Api.getCustomer(user.uid);
			customer = response.data;
			const userUidTokens = customer.userUid.split('_');
			customer.userUid = userUidTokens[userUidTokens.length - 1];
			const splitPhone = Utils.phoneToPhoneCountryAndPhone(customer.phone);
			if (splitPhone) {
				customer.phoneCountry = splitPhone.phoneCountry;
				customer.phone = splitPhone.phone;
			} else {
				customer.phoneCountry = null;
				customer.phone = '';
			}
		} catch (e) {
			console.error(e);
			customer = null;
		}
		this.sanitizeAndEmitCustomer(user, customer);
	}

	resetVenue() {
		this._venue = null;
		this.venue.emit(this._venue);
	}

	getVenue(forcedId: string | null = null): Promise<Venue> {
		let venueId = forcedId;
		if (venueId === null) {
			venueId = this.getStoredVenueId();
		}
		if (venueId === null || venueId.length <= 0) {
			if (this._venue) {
				return new Promise<Venue>(resolve => resolve(this._venue));
			} else {
				return new Promise<Venue>((resolve, reject) => reject('No Venue ID Provided'));
			}
		}
		if (this._venue && venueId === this._venue._id) {
			this.venue.emit(this._venue);
			return new Promise<Venue>(resolve => resolve(this._venue));
		}
		if (this.venuePromiseRunning === venueId) {
			return this.venuePromise;
		}
		this.venuePromiseRunning = venueId;
		this.venuePromise = new Promise<Venue>(async (resolve, reject) => {
			try {
				const venue = (await Api.getLazyVenue(venueId)).data;
				venue.openingHours = TimeUtils.sanitizeHours(venue.openingHours);
				venue.deliveryHours = TimeUtils.sanitizeHours(venue.deliveryHours);
				venue.articleCategories = (await Api.getCategoriesWithArticles(venue._id, true)).data;
				venue.movAndFee = this.sanitizeMovAndFee(venue.movAndFee);
				const optionGroups: OptionGroup[] = (await Api.getOptionGroupsByVenue(venue._id)).data;
				const allArticles = [];
				venue.articleCategories.forEach(cat => {
					allArticles.push(...cat.articles);
				});
				venue.articleCategories = venue.articleCategories
					.map(category => {
						// filter inactive categories
						category.articles = category.articles
							.map(article => {
								// filter inactive groups (empty options)
								article.groups = article.groups
									.map(groupId => {
										// @ts-ignore
										const group = optionGroups.find(grp => grp._id === groupId);
										// filter inactive options
										group.articles = group.articles
											.map(optionArticle => this.sanitizeArticle(venue, optionArticle))
											.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder));
										return group;
									})
									.filter(group => group.articles.length > 0);
								article = this.sanitizeArticle(venue, article);
								return article;
							})
							.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder));
						return category;
					})
					.map(category => {
						category.hidden = !(
							category.visible &&
							category.articles.length > 0 &&
							(!category.availableHours ||
								category.availableHours.length === 0 ||
								TimeUtils.doesHoursMatchNow(category.availableHours))
						);
						return category;
					})
					.sort((a, b) => numberComparator(a.sortOrder, b.sortOrder));
				this._venue = venue;
				this.venue.emit(this._venue);
				resolve(this._venue);
			} catch (e) {
				this.venue.emit(null);
				this.venue.error(e);
				reject(e);
			}
		}).finally(() => {
			this.venuePromiseRunning = null;
		});
		return this.venuePromise;
	}

	createOrder(venue: Venue, address: Address, preorderType: PreorderType) {
		const order = new Order();
		order._id = undefined;
		order.venue = venue._id;
		order.table = venue.preorderTable;
		order.currency = venue.currency;
		order.type = OrderType.PREORDER;
		order.preorder = new Preorder();
		order.preorder.number = '';
		order.preorder.type = preorderType;
		if (preorderType === PreorderType.TAKE_AWAY || preorderType === PreorderType.PARK_COLLECT) {
			order.preorder.street = venue.street;
			order.preorder.city = venue.city.de;
			order.preorder.number = venue.number;
			order.preorder.postalCode = venue.postalCode;
			order.preorder.country = venue.country;
			order.preorder.lat = venue.location.coordinates[1];
			order.preorder.lng = venue.location.coordinates[0];
		} else if (address) {
			order.preorder.street = address.street;
			order.preorder.city = address.city;
			order.preorder.number = address.number;
			order.preorder.postalCode = address.postalCode;
			order.preorder.country = address.country;
			order.preorder.lat = address.lat;
			order.preorder.lng = address.lng;
		}
		order.preorder.personCount = 1;
		this.order.emit(order);
	}

	async sendOrderAndPay(
		modalCtrl: ModalController,
		order: Order,
		authToken: string,
		customer: Customer,
		analytics: AngularFireAnalytics
	): Promise<any> {
		order.userUid = (await this.auth.currentUser).uid;
		this.order.emit(order);
		return new Promise<void>(async (resolve, reject) => {
			try {
				const orderCopy: Order = JSON.parse(JSON.stringify(this._order));
				if (orderCopy.preorder) {
					orderCopy.preorder.phone = orderCopy.preorder.phoneCountry.tel.substr(1) + order.preorder.phone;
				}
				if (authToken) {
					const testOrderRes = await Api.createTestOrder(authToken, orderCopy);
					const testPayment = new Payment();
					testPayment._id = testOrderRes.data.payment;
					this.payment.emit(testPayment);
					this.verifiedOrder.emit(testOrderRes.data.order);
					resolve();
					return;
				}
				const response = await Api.createOrder(orderCopy);
				AnalyticsUtils.orderVerified(order, analytics);
				const venue = await this.getVenue();
				const verifiedOrder = response.data;
				const payment = Utils.paymentFromOrder(verifiedOrder);
				const result = await PaymentModalComponent.show(modalCtrl, venue, payment, order, customer);
				if (result && result.result === PaymentModalResult.SUCCESS) {
					this.verifiedOrder.emit(verifiedOrder);
					this.payment.emit(result.payment);
					resolve();
					return;
				}
				reject(result && result.error ? result.error : null);
			} catch (e) {
				reject(e);
			}
		});
	}

	getStoredVenueId(): string {
		return this._order ? this._order.venue : null;
	}

	async reorder(translate: TranslateService, order: Order) {
		const anyOrder = JSON.parse(JSON.stringify(order));
		anyOrder.totalPrice = undefined;
		anyOrder.orderedArticles = anyOrder.orderedArticles.map(oa => {
			oa.totalPrice = undefined;
			return oa;
		});
		const localOrder: Order = anyOrder;
		localOrder.status = undefined;
		const venue = await this.getVenue(localOrder.venue);
		if (
			OrderUtils.isDelivery(localOrder) &&
			venue.deliveryEnabled &&
			venue.deliveryPostalCodes &&
			venue.deliveryPostalCodes.length > 0
		) {
			venue.isPostalDelivery = true;
		}
		if (!Utils.venueAcceptsOrders(venue, localOrder.preorder.type)) {
			throw {
				message: translate.instant('errors.venue_not_open'),
			};
		}
		const articleList = [];
		for (const category of venue.articleCategories) {
			for (const article of category.articles) {
				articleList.push(article);
				for (const group of article.groups) {
					for (const optionArticle of group.articles) {
						articleList.push(optionArticle);
					}
				}
			}
		}
		localOrder.orderedArticles = localOrder.orderedArticles
			.map(oa => {
				oa.article = articleList.find(
					article =>
						oa.article._id === article._id && Utils.isActive(articleList, article, localOrder.type, localOrder.preorder.type)
				);
				if (!oa.article) {
					return null;
				}
				oa.groups = oa.groups
					.map(ao => {
						ao.article = articleList.find(
							article =>
								ao.article._id === article._id &&
								Utils.isActive(articleList, article, localOrder.type, localOrder.preorder.type)
						);
						if (!ao.article) {
							return null;
						}
						return ao;
					})
					.filter(ao => !!ao);
				return oa;
			})
			.filter(oa => !!oa);
		localOrder.orderAt = null;
		localOrder._id = null;
		this.order.emit(OrderUtils.removePromo(localOrder));
	}

	async getVenuesByAddress(address: Address): Promise<Venue[]> {
		const venues: Venue[] = [];
		if (address.postalCode) {
			venues.push(
				...(await this.getVenues(null, null, address.postalCode))
					.map(ven => {
						ven.isPostalDelivery = true;
						return ven;
					})
					.filter(ven => {
						return Utils.venueAcceptsOrders(ven, PreorderType.DELIVERY);
					})
			);
		}
		const ids = venues.map(ven => ven._id);
		(await this.getVenues(address.lat, address.lng, null))
			.filter(ven => {
				return Utils.venueAcceptsOrders(ven, PreorderType.DELIVERY) || Utils.venueAcceptsOrders(ven, PreorderType.TAKE_AWAY);
			})
			.forEach(ven => {
				if (ids.indexOf(ven._id) < 0) {
					venues.push(ven);
				}
			});
		return venues.filter(ven => ven.customerGroup === environment.customerGroup);
	}

	async getVenues(lat: number | null, lng: number | null, postalCode: string | null): Promise<Venue[]> {
		const response = await (postalCode ? Api.getVenuesBy(postalCode) : Api.getVenues(lat, lng, 50, false));
		return response.data
			.map(venue => {
				venue.openingHours = TimeUtils.sanitizeHours(venue.openingHours);
				venue.deliveryHours = TimeUtils.sanitizeHours(venue.deliveryHours);
				venue.isPostalDelivery = !!postalCode;
				if (venue.deliveryByRadius === undefined) {
					venue.deliveryByRadius = false;
				}
				return venue;
			})
			.filter(async venue => {
				try {
					if (venue.customerGroup !== environment.customerGroup) {
						return false;
					}
					if (venue.panicEndAt && moment(venue.panicEndAt).isSameOrAfter(moment())) {
						return false;
					}
					return (
						(await Utils.getSlots(venue, PreorderType.DELIVERY)).length !== 0 ||
						(await Utils.getSlots(venue, PreorderType.TAKE_AWAY)).length !== 0
					);
				} finally {
				}
				return false;
			});
	}

	sanitizeAndEmitCustomer(user: firebase.User, customer: Customer) {
		if (customer == null) {
			customer = new Customer();
			customer.userUid = user ? sanitizeId(user.uid) : null;
		}
		customer.email = user == null || user.isAnonymous ? customer.email : user.email;
		if (!customer.phoneCountry && customer.phone) {
			const res = Utils.phoneToPhoneCountryAndPhone(customer.phone);
			customer.phoneCountry = res.phoneCountry;
			customer.phone = res.phone;
		}
		this._customer = customer;
		this.customer.emit(customer);
	}

	private sanitizeArticle(venue: Venue, article: Article): Article {
		if (article && article.groups) {
			article.isFromPrice =
				article.groups
					.map(grp => grp.displayIdentifiers && grp.displayIdentifiers.indexOf(DisplayIdentifier.fullPrice) >= 0)
					.indexOf(true) >= 0;
		}
		article.availableHours = TimeUtils.sanitizeHours(article.availableHours);
		article.hidden = !(
			article.visible &&
			article.isActive &&
			(!article.availableHours || article.availableHours.length === 0 || TimeUtils.doesHoursMatchNow(article.availableHours))
		);
		if (article.displayPrice) {
			article.price = article.displayPrice ? article.displayPrice : article.price;
		}
		if (article.displayPriceByType) {
			const byType = article.displayPriceByType;
			if (byType.standard) {
				article.priceByType.standard = byType.standard;
			}
			if (byType.preorder.delivery) {
				article.priceByType.preorder.delivery = byType.preorder.delivery;
			}
			if (byType.preorder.inside) {
				article.priceByType.preorder.inside = byType.preorder.inside;
			}
			if (byType.preorder.takeAway) {
				article.priceByType.preorder.takeAway = byType.preorder.takeAway;
			}
			if (byType.terminal.inside) {
				article.priceByType.terminal.inside = byType.terminal.inside;
			}
			if (byType.terminal.takeAway) {
				article.priceByType.terminal.takeAway = byType.terminal.takeAway;
			}
		}
		if (!article.tags) {
			article.tags = [];
		}
		article.tags = article.tags
			.filter(tag => tag !== undefined && tag !== null)
			.map(tag => {
				if (tag._id === undefined) {
					// @ts-ignore
					return venue.tags.find(srcTag => tag === srcTag._id);
				}
				return tag;
			});
		return article;
	}

	sanitizeMovAndFee(movAndFee: MovAndFee): MovAndFee {
		if (!movAndFee) {
			return movAndFee;
		}
		if (movAndFee.byRadius) {
			movAndFee.byRadius.sort((a, b) => numberComparator(a.radius, b.radius)).reverse();
		}
		if (movAndFee.byPostalCodes) {
			movAndFee.byPostalCodes = movAndFee.byPostalCodes.map(value => {
				value.fees = value.fees.sort((a, b) => numberComparator(a.from.$numberDecimal, b.from.$numberDecimal));
				return value;
			});
		}
		return movAndFee;
	}
}
