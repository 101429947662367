const { name, version } = require('../../package.json');
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const kochloeffelCustomerGroup = 'kochloeffel';
export const immergruenCustomerGroup = 'immergruen';
export const customerGroup = immergruenCustomerGroup;
const customerAnalyticsIds = {
	subway: 'UA-78275012-3',
};
const facebookPixelId = {
	immergruen: '325458221950264',
	kochloeffel: null,
};
const supportEmail = {
	kochloeffel: 'kochloeffel@smoothr.de',
	immergruen: 'immergruen@smoothr.de',
};
const firebaseConfig = {
	kochloeffel: {
		apiKey: 'AIzaSyB3-HOze6UGx01130Fmfsn5Fzb_Coh_aaA',
		authDomain: 'kochloeffel-auth-dev.firebaseapp.com',
		databaseURL: 'https://kochloeffel-auth-dev.firebaseio.com',
		projectId: 'kochloeffel-auth-dev',
		storageBucket: 'kochloeffel-auth-dev.appspot.com',
		messagingSenderId: '912981672287',
		appId: '1:912981672287:web:516bc60bde45764606b6ea',
		measurementId: 'G-4W46VE106C',
	},
	immergruen: {
		apiKey: 'AIzaSyBpH6vmTMPO_N_t-9y7Cunc91Q6EBgeCVw',
		authDomain: 'immergruen-auth-dev.firebaseapp.com',
		databaseURL: 'https://immergruen-auth-dev.firebaseio.com',
		projectId: 'immergruen-auth-dev',
		storageBucket: 'immergruen-auth-dev.appspot.com',
		messagingSenderId: '955444893844',
		appId: '1:955444893844:web:d1e299c3a813e1bb1a7c81',
		measurementId: 'G-3G7BWJRX92',
	},
};
export const environment = {
	production: false,
	packageName: name,
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	flavor: 'devDebug',
	placesApiKey: 'AIzaSyBmncPgMIKWShlEl_mxWcwjfUX92oA3slg',
	baseUrl: 'https://dev-api.smoothr.de',
	customerAnalyticsId: customerAnalyticsIds[customerGroup],
	version,
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	facebookPixelId: facebookPixelId[customerGroup],
	VR_URL: 'https://test.vr-pay-ecommerce.de/v1/paymentWidgets.js',
	PAYPAL: {
		BN_CODE: 'SterlixGmbH_MP_Connected',
		JS_URL:
			'https://www.paypal.com/sdk/js?client-id=Ac5vAqN4prOBX3YUFPX_L9Bmz3ncsdmOgkz7LuE2zP3yDbIaJB0y3SD4CTBdJqoHQhcWP6-WMklA-thJ&integration-date=2020-09-04&components=buttons,funding-eligibility&currency=',
	},
	APAY_MERCHANT_ID: 'merchant.smoothr.selforder-dev',
	GPAY: {
		GATEWAY: 'aciworldwide',
		MERCHANT_ID: 'BCR2DN6T5OYNT6CG',
	},
	countryList: ['de', 'au'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error'; // Included with Angular CLI.
