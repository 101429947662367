import { TranslateService } from '@ngx-translate/core';
import Address from '../models/Address';
import Venue from '../models/Venue';
import Article from '../models/Article';
import ArticleOption from '../models/ArticleOption';
import ArticleGroup from '../models/ArticleGroup';
import OptionGroup from '../models/OptionGroup';
import FulfilledDependency from '../models/FulfilledDependency';
import { PhoneCountry } from '../utils';
import { OrderUtils } from './order-utils';
import { environment } from '../environments/environment';

export class ValidationUtils {
	static validateAddress(translate: TranslateService, address: Address, strong: boolean = false): string {
		if (strong && !address.street) {
			return translate.instant('address.street');
		}
		if (!address.city) {
			return translate.instant('address.city');
		}
		if (!address.state) {
			return translate.instant('address.state');
		}
		if (!address.country) {
			return translate.instant('address.country');
		}
		if (strong && !address.postalCode) {
			return translate.instant('address.postalCode');
		}
		return null;
	}

	static validate(venue: Venue, article: Article, selectedOptions: ArticleOption[]) {
		let valid = article != null;
		if (valid) {
			for (const optionGroup of article.groups) {
				// ignore groups if dependencies are not fulfilled
				if (ValidationUtils.isGroupDependencyFulfilled(article, selectedOptions, optionGroup).times < 0) {
					continue;
				}
				const relevantCounts = selectedOptions
					.filter(selected => selected.group === optionGroup._id)
					.map(selected => selected.quantity);
				const relevantSelectionCount = relevantCounts.length === 0 ? 0 : relevantCounts.reduce((prev, curr) => prev + curr);
				if (
					(optionGroup.requiredAmount !== 0 && optionGroup.requiredAmount > relevantSelectionCount) ||
					(optionGroup.limit < relevantSelectionCount && optionGroup.limit !== 0)
				) {
					valid = false;
					break;
				}
			}
		}
		return valid;
	}

	static someGroupsFulfilled(articleGroup: ArticleGroup, optionGroups: OptionGroup[]): boolean {
		if (optionGroups.length === 0) {
			return true;
		}
		for (const optionGroup of optionGroups) {
			if (ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, optionGroup).times >= 0) {
				return true;
			}
		}
		return false;
	}

	static areGroupsValid(articleGroup: ArticleGroup, optionGroups: OptionGroup[]): boolean {
		for (const optionGroup of optionGroups) {
			const dependency = ValidationUtils.isGroupDependencyFulfilled(articleGroup.article, articleGroup.groups, optionGroup);
			if (!dependency || dependency.times < 0) {
				articleGroup.groups = articleGroup.groups.filter(option => option.group !== optionGroup._id);
				continue;
			}
			const matchingOptions = OrderUtils.filterMatchingOptions(articleGroup.groups, optionGroup, dependency);
			const count = matchingOptions.reduce((prev, next) => prev + next.quantity, 0);
			if (!(optionGroup.requiredAmount <= count && optionGroup.limit >= count)) {
				console.log({
					name: optionGroup.name.de,
					min: optionGroup.requiredAmount,
					max: optionGroup.limit,
					dependency,
					matchingOptions: matchingOptions.map(opt => [opt.quantity, opt.article.name.de]),
					allOptions: articleGroup.groups.map(opt => opt.article.name.de),
				});
				return false;
			}
		}
		return true;
	}

	/**
	 * @return number -1 if dependencies not fulfilled
	 *                 0 if no dependencies found so fulfilled
	 *                 n > 0 how often the dependency should be displayed
	 */
	static isGroupDependencyFulfilled(article: Article, selection: ArticleOption[], optionGroup: OptionGroup): FulfilledDependency {
		const filteredDependencies = article.groupDependencies.filter(value => value.group === optionGroup._id);
		if (filteredDependencies.length === 0) {
			return { times: 0, dependsOn: null, dependency: null };
		}
		const selectedIds = selection.map(articleOption => articleOption.article._id);
		for (const dependency of filteredDependencies) {
			const findings = dependency.dependencies.map(value =>
				value.groupArticles.map(groupArticle => selectedIds.indexOf(groupArticle))
			);
			for (const finding of findings) {
				const match = finding.map(value => value >= 0).reduce((previousValue, currentValue) => previousValue && currentValue);
				if (match) {
					return { times: selection[finding[0]].quantity, dependsOn: selection[finding[0]].article._id, dependency };
				}
			}
		}
		return { times: -1, dependsOn: null, dependency: null };
	}

	static validatePassword(password: string): boolean {
		return (
			password.length >= 8 &&
			password.match(/[a-z]|[äöüß]/) !== null &&
			password.match(/[A-Z]|[ÄÖÜ]/) !== null &&
			password.match(/[0-9]/) !== null
		);
	}

	static validatePhone(phoneCountry: PhoneCountry, phone: string): boolean {
		return (
			phone &&
			phone.length &&
			phone.length > 1 &&
			phone[0] !== '+' &&
			phoneCountry &&
			environment.countryList.indexOf(phoneCountry.code) >= 0 &&
			phone.match(/^[0-9]{1,14}?$/) !== null
		);
	}
}
