import { AngularFireAnalytics } from '@angular/fire/analytics';
import ArticleGroup from '../models/ArticleGroup';
import Order from '../models/Order';
import { OrderUtils } from './order-utils';
import { PaymentMethod } from '../enums/PaymentMethod';
import Payment from '../models/Payment';
import Article from '../models/Article';
import { PreorderType } from '../enums/PreorderType';
import { OrderType } from '../enums/OrderType';
import Utils from '../utils';
import { environment } from '../environments/environment';

export class AnalyticsUtils {
	static orderHasProduct = false;

	static logVersion(analytics: AngularFireAnalytics) {
		const eventParams = {
			customer: environment.customerGroup,
			version: environment.version,
		};
		analytics.logEvent('version', eventParams).then(() => {
			console.log({
				event: 'version',
				eventParams,
			});
		});
	}

	static addToCart(order: Order, articleGroup: ArticleGroup, analytics: AngularFireAnalytics) {
		const eventParams = {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
		};
		analytics.logEvent('add_to_cart', eventParams).then(() => {
			console.log({
				eventName: 'add_to_cart',
				eventParams,
			});
		});
	}

	static beginCheckout(order: Order, analytics: AngularFireAnalytics) {
		const eventParams = {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		};
		analytics.logEvent('begin_checkout', eventParams).then(() => {
			console.log({
				eventName: 'begin_checkout',
				eventParams,
			});
		});
	}

	static emailVerificationPageOpened(analytics: AngularFireAnalytics) {
		analytics.logEvent('email_verification_page_opened').then(() => {
			console.log({
				eventName: 'email_verification_page_opened',
			});
		});
	}

	static orderVerified(order: Order, analytics: AngularFireAnalytics) {
		const eventParams = {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		};
		analytics.logEvent('order_verified', eventParams).then(() => {
			console.log({
				eventName: 'order_verified',
				eventParams,
			});
		});
		this.checkoutProgress(order, null, 2, analytics);
	}

	static checkoutProgress(order: Order, paymentMethod: PaymentMethod, checkoutStep: number, analytics: AngularFireAnalytics) {
		const eventParams = {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			checkout_option: paymentMethod,
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		};
		analytics.logEvent('checkout_progress', eventParams).then(() => {
			console.log({
				eventName: 'checkout_progress',
				eventParams,
			});
		});
	}

	static paymentLoaded(order: Order, paymentMethod: PaymentMethod, analytics: AngularFireAnalytics) {
		const eventParams = {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			checkout_option: paymentMethod,
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		};
		analytics.logEvent('payment_loaded', eventParams).then(() => {
			console.log({
				eventName: 'payment_loaded',
				eventParams,
			});
		});
		AnalyticsUtils.checkoutProgress(order, paymentMethod, 3, analytics);
	}

	static purchase(order: Order, payment: Payment, analytics: AngularFireAnalytics) {
		const eventParams = {
			currency: order.currency,
			value: OrderUtils.orderTotalPrice(order, true, true),
			items: order.orderedArticles.map(orderedArticle => {
				return {
					id: orderedArticle.article.masterId,
					name: orderedArticle.article.name.de,
					quantity: orderedArticle.quantity,
					price: OrderUtils.totalPrice(orderedArticle, order.type, order.preorder.type, null),
				};
			}),
			transaction_id: payment._id,
			shipping: order.preorder.deliveryFee ? order.preorder.deliveryFee : undefined,
			coupon: order.promoCode && order.promoCode.code ? order.promoCode.code : undefined,
		};
		analytics.logEvent('purchase', eventParams).then(() => {
			console.log({
				eventName: 'purchase',
				eventParams,
			});
		});
	}

	static search(searchTerm: string, analytics: AngularFireAnalytics) {
		const eventParams = {
			search_term: searchTerm,
		};
		analytics.logEvent('search', eventParams).then(() => {
			console.log({
				eventName: 'search',
				eventParams,
			});
		});
	}

	static selectContentProduct(article: Article, orderType: OrderType, preorderType: PreorderType, analytics: AngularFireAnalytics) {
		const eventParams = {
			content_type: 'product',
			items: {
				id: article.masterId,
				name: article.name.de,
				quantity: 1,
				price: Utils.getPrice(article, orderType, preorderType, null),
			},
		};
		analytics.logEvent('select_content', eventParams).then(() => {
			console.log({
				eventName: 'select_content',
				eventParams,
			});
		});
	}

	static visitMenuPage(analytics: AngularFireAnalytics) {
		analytics.logEvent('visit_menu_page').then(() => {
			console.log({
				eventName: 'visit_menu_page',
			});
		});
	}

	static openOrderContent(analytics: AngularFireAnalytics) {
		analytics.logEvent('visit_order_content').then(() => {
			console.log({
				eventName: 'visit_order_content',
			});
		});
	}

	static orderHasProducts(analytics: AngularFireAnalytics) {
		if (AnalyticsUtils.orderHasProduct) {
			return;
		}
		AnalyticsUtils.orderHasProduct = true;
		analytics.logEvent('order_has_products').then(() => {
			console.log({
				eventName: 'order_has_products',
			});
		});
	}

	static customerBeginsCheckout(analytics: AngularFireAnalytics) {
		analytics.logEvent('checkout_modal_opened').then(() => {
			console.log({
				eventName: 'checkout_modal_opened',
			});
		});
	}

	static paymentModalOpened(analytics: AngularFireAnalytics) {
		analytics.logEvent('payment_modal_opened').then(() => {
			console.log({
				eventName: 'payment_modal_opened',
			});
		});
	}

	static paymentSuccess(analytics: AngularFireAnalytics) {
		analytics.logEvent('payment_success').then(() => {
			console.log({
				eventName: 'payment_success',
			});
		});
	}

	static beginArticleEdit(article: Article, analytics: AngularFireAnalytics) {
		const eventParams = {
			article: article.name.de,
			id: article._id,
		};
		analytics.logEvent('begin_article_edit', eventParams).then(() => {
			console.log({
				eventName: 'begin_article_edit',
				eventParams,
			});
		});
	}

	static stepArticleEdit(article: Article, step: number, analytics: AngularFireAnalytics) {
		const eventParams = {
			article: article.name.de,
			step,
			id: article._id,
		};
		analytics.logEvent('step_article_edit', eventParams).then(() => {
			console.log({
				eventName: 'step_article_edit',
				eventParams,
			});
		});
	}

	static finishArticleEdit(article: Article, step: number, analytics: AngularFireAnalytics) {
		const eventParams = {
			article: article.name.de,
			step,
			id: article._id,
		};
		analytics.logEvent('finish_article_edit', eventParams).then(() => {
			console.log({
				eventName: 'finish_article_edit',
				eventParams,
			});
		});
	}

	static cancelArticleEdit(article: Article, step: number, analytics: AngularFireAnalytics) {
		const eventParams = {
			article: article.name.de,
			step,
			id: article._id,
		};
		analytics.logEvent('cancel_article_edit', eventParams).then(() => {
			console.log({
				eventName: 'cancel_article_edit',
				eventParams,
			});
		});
	}
}
