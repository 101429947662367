import { Component, Input } from '@angular/core';
import Utils from '../../../utils';
import { Router } from '@angular/router';
import Order from '../../../models/Order';
import { OrderUtils } from '../../../utils/order-utils';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-to-order-button',
	templateUrl: './to-order-button.component.immergruen.html',
	styleUrls: ['./to-order-button.component.immergruen.scss'],
})
export class ToOrderButtonComponent {
	utils = Utils;
	@Input() order: Order;

	constructor(private router: Router) {}

	get price(): number {
		if (this.order === undefined || this.order === null) {
			return 0;
		}
		return OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null
		);
	}

	get active(): boolean {
		return this.price > 0;
	}

	goToOrder() {
		if (this.active) {
			this.router.navigateByUrl('order');
		}
	}
}
