import { Component, OnInit } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import Article from '../../../models/Article';
import ArticleOption from '../../../models/ArticleOption';
import * as moment from 'moment';
import { MatSnackBar } from '@angular/material/snack-bar';
import Utils from '../../../utils';
import { MenuPage } from '../menu/menu.page';
import { PreorderType } from '../../../enums/PreorderType';
import { AppComponent } from '../../app.component';
import RepositoryInterface from '../repository-directive';
import { TranslateService } from '@ngx-translate/core';
import { OrderUtils } from '../../../utils/order-utils';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.immergruen.html',
	styleUrls: ['./order.page.immergruen.scss'],
})
export class OrderPage extends RepositoryInterface implements OnInit {
	static url = 'order';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static paymentFailUrl = 'payment/fail';
	static paymentCancelUrl = 'payment/cancel';

	moment = moment;

	isValid = false;
	loading = false;

	utils = Utils;
	pt = PreorderType;
	differenceToMvo: number;

	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private route: ActivatedRoute,
		private alertCtrl: AlertController
	) {
		super(repository);
		this.validate();
	}

	static navigate(router: Router) {
		return router.navigateByUrl(AppComponent.largeScreen ? MenuPage.url : OrderPage.url);
	}

	onVenue() {
		super.onVenue();
		this.validate();
	}

	onOrder() {
		super.onOrder();
		this.validate();
	}

	ngOnInit(): void {
		console.log('ngOnInit');
		super.ngOnInit();
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000,
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000,
				});
				break;
		}
	}

	backToMenu() {
		MenuPage.navigate(this.router);
	}

	validate() {
		if (!this.order || !this.venue || !this.order.preorder) {
			this.isValid = false;
			return;
		}
		const mov = this.order.preorder.type === PreorderType.DELIVERY ? +this.venue.movDelivery.$numberDecimal : 0;
		const orderValue = OrderUtils.articleGroupsTotalPrice(
			this.order.orderedArticles,
			this.order.type,
			this.order.preorder.type,
			this.order.terminalorder ? this.order.terminalorder.type : null
		);
		this.differenceToMvo = orderValue - mov;
		this.isValid = this.differenceToMvo >= 0;
	}

	totalPrice(): string {
		return Utils.numberToCurrency(OrderUtils.orderTotalPrice(this.order, true, true), this.order.currency);
	}

	replace(index: number, article: Article, options: ArticleOption[]) {
		this.order.orderedArticles[index].quantity = 1;
		this.order.orderedArticles[index].article = article;
		this.order.orderedArticles[index].groups = options;
	}
}
