import { Component, Input } from '@angular/core';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';
import ArticleGroup from '../../../models/ArticleGroup';
import { OrderUtils } from '../../../utils/order-utils';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.immergruen.html',
	styleUrls: ['./item-view.component.immergruen.scss'],
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	utils = Utils;
	ot = OrderType;

	constructor() {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			if (this.article.isFromPrice) {
				const articleGroup = new ArticleGroup();
				articleGroup.article = this.article;
				articleGroup.groups = Utils.defaultsToArticleOption(this.article, this.article.defaults, this.preorderType);
				articleGroup.quantity = 1;
				this.price = OrderUtils.totalPrice(articleGroup, OrderType.PREORDER, this.preorderType);
			} else {
				this.price = Utils.getPrice(this.article, OrderType.PREORDER, this.preorderType);
			}
		} else {
			this.price = 0;
		}
	}
}
