import { Gender } from '../enums/Gender';

export default class Customer {
	_id?: string;
	name?: string;
	street?: string;
	city?: string;
	postalCode?: string;
	companyName?: string;
	preferredVenue?: string;
	number?: string;
	gender?: Gender;
	phoneCountry?: { code: string; tel: string };
	phone?: string;
	email: string;
	userUid: string;
	country?: string;
	floor?: string;
	subcard?: string;
	loyaltyNumber?: string;
}
