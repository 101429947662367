import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { HomePage } from '../home/home.page';
import { MenuPage } from '../menu/menu.page';
import { RepositoryService } from '../../services/repository/repository.service';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-tos',
	templateUrl: './tos.page.immergruen.html',
	styleUrls: ['./tos.page.immergruen.scss'],
})
export class TosPage {
	static url = 'tos';
	environment = environment;

	constructor(private repository: RepositoryService, private router: Router) {}

	static async navigate(router: Router) {
		await router.navigateByUrl(TosPage.url);
	}

	goBack() {
		if (this.repository._order === null) {
			HomePage.navigate(this.router);
		} else {
			MenuPage.navigate(this.router);
		}
	}
}
