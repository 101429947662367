import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { PaymentSuccessPage } from './payment-success.page';
import { QRCodeModule } from 'angular2-qrcode';
import { SharedModuleModule } from '../../shared-module/shared-module.module';

const routes: Routes = [
	{
		path: '',
		component: PaymentSuccessPage,
	},
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), QRCodeModule, SharedModuleModule],
	declarations: [PaymentSuccessPage],
})
export class SuccessPageModule {}
