import { Component, EventEmitter, Input, Output } from '@angular/core';
import OptionGroup from '../../../models/OptionGroup';
import ArticleOption from '../../../models/ArticleOption';
import Article from '../../../models/Article';
import Utils from '../../../utils';
import { DisplayIdentifier } from '../../enums/DisplayIdentifier';
import { OrderType } from '../../../enums/OrderType';
import { PreorderType } from '../../../enums/PreorderType';
import { environment } from '../../../environments/environment';

@Component({
	selector: 'app-article-option-group-checkbox',
	templateUrl: './article-option-group-checkbox.component.immergruen.html',
	styleUrls: ['./article-option-group-checkbox.component.immergruen.scss'],
})
export class ArticleOptionGroupCheckboxComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Input() currency: string;
	@Input() preorderType: PreorderType;

	utils = Utils;
	ot = OrderType;
	di = DisplayIdentifier;

	constructor() {}

	isHidden(article: Article): boolean {
		return this.preorderType && !Utils.getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	onSelectionChange(article: Article) {
		if (this.isDisabled(article)) {
			return;
		}
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	isDisabled(option: Article): boolean {
		return option.requirements && option.requirements.min > 0 && this.isOptionSelected(option);
	}

	isOptionSelected(option: Article): boolean {
		return this.selected.find(sel => sel.article._id === option._id && sel.group === this.optionGroup._id) !== undefined;
	}
}
