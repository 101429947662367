import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RepositoryService } from '../../services/repository/repository.service';
import Utils from '../../../utils';
import Article from '../../../models/Article';
import { PreorderType } from '../../../enums/PreorderType';
import { OrderType } from '../../../enums/OrderType';
import { environment } from '../../../environments/environment';
import { DisplayMode } from '../../../enums/DisplayMode';
import OptionGroup from '../../../models/OptionGroup';

@Component({
	selector: 'app-item-selectable-article',
	templateUrl: './item-selectable-article.component.immergruen.html',
	styleUrls: ['./item-selectable-article.component.immergruen.scss'],
})
export class ItemSelectableArticleComponent {
	@Input() article: Article;
	@Input() selected: number;
	@Input() preorderType: PreorderType;
	@Input() group: OptionGroup;
	@Output() add = new EventEmitter();
	@Output() remove = new EventEmitter();
	DisplayMode = DisplayMode;
	utils = Utils;
	currency: string;

	constructor(public repository: RepositoryService) {
		this.currency = repository._order ? repository._order.currency : null;
		this.repository.order.subscribe(order => (this.currency = order ? order.currency : null));
	}

	get price(): number {
		return Utils.getPrice(this.article, OrderType.STANDARD, this.preorderType);
	}

	get isEmptyArticle(): boolean {
		return this.article.tags.findIndex(tag => tag.identifier === 'empty') >= 0;
	}
}
