import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { SignInPage } from './sign-in.page';
import { SharedModuleModule } from '../../shared-module/shared-module.module';

const routes: Routes = [
	{
		path: '',
		component: SignInPage,
	},
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModuleModule],
	declarations: [SignInPage],
})
export class SignInPageModule {}
