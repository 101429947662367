import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-add-to-home',
	templateUrl: './add-to-home.component.html',
	styleUrls: ['add-to-home.component.scss'],
})
export class AddToHomeComponent implements OnInit {
	constructor() {}

	ngOnInit() {}

	close() {
		console.log('Close clicked');
	}
}
