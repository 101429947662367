import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AccountPageRoutingModule } from './account-routing.module';

import { AccountPage } from './account.page';
import { SharedModuleModule } from '../../shared-module/shared-module.module';

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, AccountPageRoutingModule, SharedModuleModule],
	declarations: [AccountPage],
})
export class AccountPageModule {}
