import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { IonicModule } from '@ionic/angular';

import { MyOrdersPage } from './my-orders.page';
import { SharedModuleModule } from '../../shared-module/shared-module.module';
import { QRCodeModule } from 'angular2-qrcode';

const routes: Routes = [
	{
		path: '',
		component: MyOrdersPage,
	},
];

@NgModule({
	imports: [CommonModule, FormsModule, IonicModule, RouterModule.forChild(routes), SharedModuleModule, QRCodeModule],
	declarations: [MyOrdersPage],
})
export class MyOrdersPageModule {}
